import { $api } from '@/api/api';
import {
    CompetitionType,
    ICompetition,
    ICreateCompetition,
    ICreateContestResponse,
    IEditCompetition,
    IGetAllParams,
    IGetModerationListParams,
    ILikeParticipantPayload,
    IRequestUserParticipant,
    ISetUserModerationStatus,
} from '../types/services/Competiton.type';
import { IApiResponse } from '../types/Api.type';
import { createQueryStringByParams } from '../helpers/createQueryStringByParams';

class competitionsService {
    async getAll(params?: IGetAllParams, apiKey?: string | null): Promise<ICompetition[]> {
        const queryString = createQueryStringByParams(params);

        return (
            await $api.get(
                `competitions
				${queryString}`,
                {
                    headers: {
                        apiKey,
                    },
                }
            )
        ).data;
    }

    async getModerationList(params: IGetModerationListParams): Promise<IRequestUserParticipant[]> {
        const queryString = createQueryStringByParams(params);
        return (await $api.get(`moderation${queryString}`)).data;
    }

    async setUserModerationStatus(params: ISetUserModerationStatus, description?: string) {
        const queryString = createQueryStringByParams(params);
        let data = {};

        if (description) {
            data = {
                description,
            };
        }

        return (await $api.put(`moderation${queryString}`, data)).data;
    }

    async createCompetition(payload: ICreateCompetition): Promise<ICreateContestResponse> {
        return (await $api.post('/competitions', payload)).data;
    }

    async editCompetition(payload: IEditCompetition): Promise<IApiResponse> {
        return (await $api.put('/competitions', payload)).data;
    }

    async likeParticipant(payload: ILikeParticipantPayload) {
        return (await $api.put('/profiles', payload)).data;
    }

    async getContestIds(competitionType: CompetitionType): Promise<number[]> {
        return (await $api.get(`/active?competition_type=${competitionType}`)).data;
    }

    async setMainCompetition(competition_id: number): Promise<IApiResponse> {
        return (await $api.put(`/active?competition_id=${competition_id}`)).data;
    }
}

export const CompetitionsService = new competitionsService();
